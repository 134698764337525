import Flickity from 'flickity';

const addFlickityToTabs = () => {
  const getFlickityContainer = document.querySelectorAll('.wp-block-bigbite-tabs .tabsContainer');

  getFlickityContainer.forEach((element) => {
    // eslint-disable-next-line no-unused-vars
    const flcktyInstance = new Flickity(element, {
      cellAlign: 'left',
    });
  });

  /* eslint-disable no-param-reassign */
  const handleClick = () => {
    const grabAllButtons = document.querySelectorAll('.wp-block-bigbite-tabs .tabsContainer .tab');
    const grabAllPanels = document.querySelectorAll(
      '.wp-block-bigbite-tabs .tabPanelContainer .tabPanel',
    );

    grabAllButtons.forEach((button) => {
      if (button.className.includes('is-selected')) {
        const selectedTab = button.id.slice(4);

        grabAllPanels.forEach((panel) => {
          if (panel.id.includes(selectedTab)) {
            panel.className += ' is-selected';
            panel.ariaExpanded = true;
          } else {
            panel.classList.remove('is-selected');
            panel.ariaExpanded = false;
          }
        });
      }
    });
  };

  const getTabNavArrows = document.querySelectorAll(
    '.wp-block-bigbite-tabs .tabsContainer .flickity-button',
  );

  getTabNavArrows.forEach((element) => {
    element.addEventListener('click', handleClick);
  });
};

export default addFlickityToTabs;
